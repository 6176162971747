// Here all the paths are suffixed with "_PATH" as leaf nodes,
// to access a sub-directory we can create a new obj and create paths as mentioed above.
export const APP_ROUTES = {
  BASE_PATH: '/app',
  APP: {
    PLAYER_PATH: '/app/player',
    HOME_PATH: '/app/home',
    PROFILE_PATH: '/app/profile',
    PROFILE: {
      USER_PATH: '/app/profile/user',
      SETTINGS_PATH: '/app/profile/setting',
    },
    SUB_PAGES: {
      CATALOGPAGES: {
        DETAILS_PAGE: '/app/subPages/catalog/detailsPage',
        MORE_DETAILS_PAGE: '/app/subPages/catalog/DetailsPage/moreDetailsPage',
      },
      DESTINATION_PAGE: '/app/subPages/destinationPage',
      SECTION_PAGE: '/app/subPages/sectionPage',
    },
    CREATORS: {
      NEW_STORY: '/app/creators/newStory',
    },
    CREDENTIALS: {
      LOGIN_PAGE: '/app/login',
      SIGNUP_PAGE: '/app/signup',
	  FORGOT_PASSWORD_PAGE: '/app/login/forgotPassword',
    },
  },
  LANDING_PAGE: {
    JOIN_WAITLIST: '/register',
  },
};
