import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconFactory,
  IconFactoryColors,
  IconFactorySizes,
  IconFactoryTypes,
} from 'commons/Icons/IconFactory';

const ButtonStyleType = {
  Login:
    'bg-purple-500 my-3 py-3 rounded-full font-bold w-3/4 mx-auto flex justify-center items-center tracking-wider',
};

const CommonButton = ({ ButtonName, btnStyle, handleClick }) => {
  const [loading, setLoading] = useState(false);
  const handleLoading = (e) => {
    setLoading(true);
    handleClick(e);
  };
  return (
      <button
      onClick={(e) => handleLoading(e)}
      type="button"
      className={`${ButtonStyleType[btnStyle]}`}
    >
          {loading ? (
              <><IconFactory
          iconName={IconFactoryTypes.LOADER}
          iconSize={IconFactorySizes.large}
          iconColor={IconFactoryColors.LOADING}
        /><span className="px-2">Processing...</span>
              </>
      ) : (
        ButtonName
      )}
      </button>
  );
};

CommonButton.propTypes = {
  btnStyle: PropTypes.oneOf(['Login', 'SignUp']).isRequired,
  ButtonName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export { CommonButton };