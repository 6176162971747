export const ACTION = {
  PROFILE_DETAILS: "PROFILE_DETAILS",
  PROFILE_STATUS: "PROFILE_STATUS",
  PROFILE_LOGOUT: "PROFILE_LOGOUT",
};

export function userDataAction(data) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: ACTION.PROFILE_DETAILS,
        payload: {
          userName: data.name,
          email: data.email,
          picture: data.picture,
          isUserSignedIn: true,
        },
      });
      resolve(); // Indicate that dispatching action was successful
    });
  };
}

export function logoutAction() {
  return (dispatch) =>
    dispatch({
      type: ACTION.PROFILE_LOGOUT,
      payload: {
        isUserSignedIn: false,
      },
    });
}
