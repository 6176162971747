import React from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { userDataAction } from "portal/app/profilePage/profile.actions";
import ButtonElement from "./buttonElement";
import { loginWithGoogle } from "commons/Google-OAuth/google_OAuth";

function LoginComponent(props) {
  const { SetUserDataInProfile } = props;

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const loginData = await loginWithGoogle(tokenResponse.code);
      SetUserDataInProfile(loginData.user_details);
    },
    flow: 'auth-code', // implicit is the default
  });

  return (
      <div className="flex flex-col text-base font-bold cursor-pointer">
          <ButtonElement btnStyle="signIn" onClick={() => googleLogin()}>Continue with Google 🚀</ButtonElement>
      </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
    SetUserDataInProfile: (userInfo) => dispatch(userDataAction(userInfo)),
});

const GoogleSSOButton = connect(null, mapDispatchToProps)(LoginComponent);

export { GoogleSSOButton };
