import axios from 'axios';
import { refreshJwt } from 'commons/Google-OAuth/google_OAuth';

// Helper function to get token from localStorage
const getToken = () => localStorage.getItem('access_token_cookie');

let access_token = getToken();

// Request interceptor
axios.interceptors.request.use(
    async config => {
        console.log('Interceptor: Adding auth header', access_token);
        if (access_token) {
            config.headers['Authorization'] = `Bearer ${access_token}`;
        }
        console.log('Config after interceptor:', config);
        return config;
    },
    error => Promise.reject(error)
);

// Response interceptor
// Response interceptor
axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        // Check if the error is due to expired token (401 status)
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await refreshJwt();
                console.log('Response Interceptor: token refresh success');
                access_token = getToken();
                // Update the Authorization header with new token
                originalRequest.headers['Authorization'] = `Bearer ${access_token}`;

                return axios(originalRequest);
            } catch (error) {
                console.error('Error refreshing token:', error);
                localStorage.removeItem('accessToken');
                // window.location.href = '/login'; // Redirect to login page
            }
        }

        return Promise.reject(error);
    }
);
