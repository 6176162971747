import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { APP_ROUTES } from 'configs/AppRoutes';
import { BottomMostBlock } from 'commons/PageElements/index';
import { IconFactory, IconFactoryTypes } from 'commons/Icons/IconFactory';
import './bottomNavigation.css';

function NavIcon({ iconName }) {
  switch (iconName) {
    case 'Player':
      return <IconFactory iconName={IconFactoryTypes.PLAYER} />;
    case 'Home':
      return <IconFactory iconName={IconFactoryTypes.HOME} />;
    case 'Profile':
      return <IconFactory iconName={IconFactoryTypes.PROFILE} />;
    default:
      return <IconFactory iconName={IconFactoryTypes.ERROR} />;
  }
}

NavIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
};

function NavItem({
  navTitle, navLink, selectedTab, onClickHandler,
}) {
  const isSelected = selectedTab === navTitle;
  return (
      <li className="transition duration-300 ease-in-out">
          <NavLink
            className={`text-xs font-normal flex flex-col justify-center items-center`}
            to={navLink}
            onClick={onClickHandler}
          >
              <span className={`${isSelected && 'selected-tab'}`}>
                  <NavIcon iconName={navTitle} />
              </span>
              {navTitle}
          </NavLink>
      </li>
  );
}

NavItem.propTypes = {
  navTitle: PropTypes.string.isRequired,
  navLink: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

function BottomNavbar() {
  const [selectedTab, setSelectedTab] = useState('Home');
  return (
      <>
          <div className="fixed bottom-0 z-10 m-auto left-0 right-0 rounded-t-xl py-2 px-4 text-white bg-[#121b2231] backdrop-blur-xl md:w-7/12 md:p-1 lg:w-4/6 xl:h-12 xl:w-1/4">
              <ul className="flex items-center justify-evenly md:text-base ">
                  <NavItem
                    navTitle="Player"
                    navLink={APP_ROUTES.APP.PLAYER_PATH}
                    selectedTab={selectedTab}
                    onClickHandler={() => setSelectedTab('Player')}
                  />
                  <NavItem
                    navTitle="Home"
                    navLink={APP_ROUTES.APP.HOME_PATH}
                    selectedTab={selectedTab}
                    onClickHandler={() => setSelectedTab('Home')}
                  />
                  <NavItem
                    navTitle="Profile"
                    navLink={APP_ROUTES.APP.PROFILE_PATH}
                    selectedTab={selectedTab}
                    onClickHandler={() => setSelectedTab('Profile')}
                  />
              </ul>
          </div>
          <BottomMostBlock />
      </>
  );
}

export default BottomNavbar;