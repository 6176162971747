/* eslint-disable default-param-last */
import { ACTION } from "./profile.actions";

const initialState = {
  userName: "Guest",
  email: undefined,
  picture: "/originalImgs/31s.jpg",
};  
export default function (state = initialState, action) {
	switch (action.type) {
	  case ACTION.PROFILE_STATUS:
    case ACTION.PROFILE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION.PROFILE_LOGOUT:
      return {
        ...state,
        ...action.payload,
        ...initialState,
      }
    default:
      return state;
  }
}
