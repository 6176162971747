import { NewFormInput } from "commons/formInputs/TextInput/NewTextInput";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "configs/AppRoutes";
import { CommonButton } from "../commonElement/commonButton";

const logo = "/originalImgs/removedBgImgs/header_img.png";

const LoginPage = () => {
  const routeNavigator = useNavigate();
  const [InputValue, setInputValue] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("values:", InputValue);
    setTimeout(() => routeNavigator(APP_ROUTES.APP.PROFILE_PATH), 5000);
  };

  const handleInputChange = (name, value) => {
    setInputValue((prevState) => ({ ...prevState, [name]: value }));
  };

  function handleKeyDown(e) {
    e.preventDefault();
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }
  return (
      <div className="mx-auto text-center">
          <div className="my-2">
              <img className="mx-auto size-36" src={logo} alt="Storymode Logo" />
          </div>

          <div className="text-center text-2xl tracking-wide ">
              Welcome!
              <div>
                  to <span className="text-purple-400">Storymode</span>
              </div>
          </div>

          <form
        onSubmit={handleSubmit}
        className="mx-auto my-10 w-11/12 text-center"
      >
              <NewFormInput
          name="Username"
          type="text"
          placeholder="Enter your username"
          label="Username"
          onTextInputChange={(name, value) => handleInputChange(name, value)}
          required
        />
              <NewFormInput
          name="Password"
          type="password"
          placeholder="Enter your password"
          label="Password"
          onTextInputChange={(name, value) => handleInputChange(name, value)}
          required
        />
              <CommonButton
          ButtonName="Login"
          btnStyle="Login"
          handleClick={(e) => handleSubmit(e)}
        />
              <span
          onClick={() =>
            routeNavigator(APP_ROUTES.APP.CREDENTIALS.FORGOT_PASSWORD_PAGE)
          }
          className="items-center text-center text-purple-300"
        >
                  Forgot Password?
              </span>
              <div>
                  <p className="my-2">or</p>
                  {/* <GoogleAuth /> */}
              </div>
          </form>
          <span>
              Don&apos;t have an account?{" "}
              <span
          className="text-purple-300"
          role="button"
          onKeyDown={(e) => handleKeyDown(e)}
          tabIndex={0}
          onClick={() => routeNavigator(APP_ROUTES.APP.CREDENTIALS.SIGNUP_PAGE)}
        >
                  Sign up
              </span>
          </span>
      </div>
  );
};
export { LoginPage };
