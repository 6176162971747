/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import BottomNavbar from 'components/BottomNavigation/BottomNavbar';
import { HeaderBar } from 'commons/header/headerContainers/headerBar';
import LandingPage from 'components/StaticSite/landingPage';
import { RouteNotFound } from 'commons/ErrorBoundary';
import { APP_ROUTES } from 'configs/AppRoutes';
import { RegisterPage } from 'components/StaticSite/staticSections/preRegisterPage/registerPage';
import { PortalContainer } from './elements/parentContainer';
import { appRoutes } from './portal.routes';
import configureStore from './portal.store';
import '../configs/Interceptors/AxiosInterceptor';

function AppPortal() {
    const location = window.location.href;
    const store = configureStore();
    //Check if the pathname starts with "/app"
    const isAppRoute = location.includes(APP_ROUTES.BASE_PATH);

    if (isAppRoute) {
        return (

            <Provider store={store}>
                <PortalContainer>
                    <HeaderBar />
                    <Routes>
                        {appRoutes.map(({
                            path, exact, View, ...rest
                        }) => (
                            <Route key={path} path={path} element={<View {...rest} />} />
                        ))}
                        <Route path="*" element={<RouteNotFound />} />
                        <Route index element={<LandingPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="*" element={<RouteNotFound />} />
                    </Routes>
                    <BottomNavbar />
                </PortalContainer>
            </Provider>
        );
    }

    return (
        <Routes>
            <Route index element={<LandingPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<RouteNotFound />} />
        </Routes>
    );
}

export default AppPortal;
