import { API_ENDPOINTS } from 'configs/APIEndpoints/index';
import axios from 'axios';

// Function to get all cookies
function getAllCookies() {
  var cookies = {
    "access_token_cookie": localStorage.getItem('access_token_cookie'),
  };

  return cookies;
}

export async function loginWithGoogle(authCode) {
  try {
    const response = await fetch(`${API_ENDPOINTS.BASE}/auth/google`, {
      method: 'POST',
      credentials: 'include', // Include cookies in the request
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth_code: authCode }),
    });

    const data = await response.json();
    
    if (response.ok) {
      console.log("Login successful");
      
      // Store access token in local storage
      await localStorage.setItem('access_token_cookie', data.access_token);
      return data;

    } else {
      console.error("Login Error:", data.error);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
}

export async function refreshJwt() {
  try {
    const cookies = getAllCookies();
    const accessToken = cookies.access_token_cookie;

    const response = await fetch(`${API_ENDPOINTS.BASE}/auth/refresh_token`, {
      method: 'GET',
      credentials: 'include', // Include cookies in the request
      headers: {
          'Authorization': `Bearer ${accessToken}`, // Include the JWT in the Authorization header
      },
    });

    const data = await response.json();
    
    if (response.ok) {
      localStorage.setItem('access_token_cookie', data.access_token);
    } else {
      console.error("Refresh Error:", data.error);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
}


export const fetchProtectedData = async () => {
  try {
    const response = await axios.get(`${API_ENDPOINTS.BASE}/protected`);

    return response.data;
  } catch (error) {
    console.error("Error fetching protected data:", error.message);
  }
};

// export const fetchProtectedData = async () => {
//     const token = localStorage.getItem('access_token_cookie'); // Retrieve the JWT from local storage
//     try {
//         const response = await fetch(`${API_ENDPOINTS.BASE}/protected`, {
//             method: 'GET',
//             headers: {
//                 'Authorization': `Bearer ${token}`, // Include the JWT in the Authorization header
//             },
//         });

//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }

//         const result = await response.json();
//         return result;
//     } catch (err) {
//         console.error("Error fetching protected data:", err.message);
        
//         // If unauthorized, try refreshing the token
//         // if (err.message.includes("401")) {
//         //     await refreshJwt();
//         //     // Retry fetching protected data after refreshing
//         //     await fetchProtectedData();
//         // }
//     }
// };
