import React, {useEffect} from "react";
import { AudioBar } from "./element/audioBar";
import { connect } from "react-redux";
import { detailsPageJSON } from "../subPages/catalogPages/detailsPage/detailsPage.data";
import {
  SetPlayerMediaAction,
  SetMoreLikeThisPlaylist,
  resetPlayerStateAction,
} from "./player.actions";
import { MoreLikeThisSection } from "./element/moreLikeThisSection";
import { reorderPlaylistByPlayStatus, scrollToTop } from "./player.helpers";

function PlayStatus({ nowPlayingId, audioDetails }) {
  const isNowPlaying = nowPlayingId === audioDetails.sectionID;
  const hasPlayed = audioDetails.hasPlayed;

  if (isNowPlaying) {
    return <small className="text-green-600">Now Playing</small>;
  } else if (hasPlayed) {
    return <small className="text-green-600">Played</small>; // Assuming this should indicate 'Played'
  } else {
    return <small>4:30</small>;
  }
}

function AudioPlayerComponent(props) {
  const { playlist, playerImage, playerAudio, nowPlayingId, nowPlayingTitle, viewedMedia } = props;
  const { SetPlayersMedia, ResetPlayerState } = props;
  console.log("now playing", nowPlayingId);
  const playlistLength = playlist.length;

  const orderedPlaylist = reorderPlaylistByPlayStatus(playlist, viewedMedia);
  
    // Use useEffect for cleanup on unmount
    useEffect(() => {
      scrollToTop();
      // Return a cleanup function that dispatches reset action
      return () => {
        // console.log("Component will unmount - resetting Redux state");
        ResetPlayerState();
      };
    }, []); // Empty array means this runs once when component mounts/unmounts
  
  return (
      <div>
          <div className="my-2 text-xl bottom-0 font-bold tracking-wider">
              <h1>{nowPlayingTitle}</h1>
          </div>
          <AudioBar imageURL={playerImage} audioURL={playerAudio} />
          <MoreLikeThisSection />
          <div className="">
              <div className="my-5 text-xl font-bold tracking-wider">
                  <h1>All Sections ({playlistLength})</h1>
              </div>
              <div className="my-8 font-semibold">
                  {orderedPlaylist.map((audioDetails, key) => (
                      <div
                        key={key}
                        className="my-3 flex w-full items-start justify-start gap-5"
                        onClick={() => SetPlayersMedia(audioDetails)}
                      >
                          <img
                              loading="lazy"
                              src={audioDetails.sectionImg}
                              className="size-20 rounded-xl"
                              alt="audio icon"
                            />
                          <div className="flex-col items-center justify-center gap-2 py-4">
                              <p className="text-sm font-medium">
                                  {audioDetails.sectionName}
                              </p>
                              <PlayStatus nowPlayingId={nowPlayingId} audioDetails={audioDetails} />
                          </div>
                      </div>
                    ))}
              </div>
          </div>
      </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    playlist: state.player.playlist || detailsPageJSON.pageSections,
    playerImage: state.player.playerImageURL || state.player.defaultPlayerImage,
    playerAudio: state.player.playerAudioURL || state.player.defaultPlayerAudio,
    nowPlayingId: state.player.playerMediaId || null,
    nowPlayingTitle: state.player.nowPlayingTitle || "Select audio to play",
    viewedMedia: state.player.viewedMedia || [],
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    SetPlayersMedia: (audioDetails) => {
      dispatch(SetPlayerMediaAction(audioDetails));
      dispatch(SetMoreLikeThisPlaylist(audioDetails));
      scrollToTop();
    },
    ResetPlayerState: () => dispatch(resetPlayerStateAction())
  };
};

// Connect the component and export it
const AudioPlayer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioPlayerComponent);

export { AudioPlayer };
