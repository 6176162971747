import React from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from 'styled-components';
import Portal from './portal/portal';
import theme from './commons/theme';
import { AppLevelErrorBoundary } from './commons/ErrorBoundary';

function App() {
  return (
      <GoogleOAuthProvider clientId="748922790987-msvt5pmlj5vu60gijctq8ptimuamk919.apps.googleusercontent.com">
          <ThemeProvider theme={theme}>
              <BrowserRouter>
                  <AppLevelErrorBoundary>
                      <Portal />
                  </AppLevelErrorBoundary>
              </BrowserRouter>
          </ThemeProvider>
      </GoogleOAuthProvider>
  );
}
export default App;
