import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formElementStyle } from '../elements/formElementsStyle';

const { labelStyle, boxInput, constantStyle } = formElementStyle;

function NewFormInput({
  name,
  type,
  placeholder,
  label,
  formHandler,
  onTextInputChange,
  required,
}) {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const id = name;

  const handleChange = ((event) => {
    event.preventDefault();
    setInputValue(event.target.value);
    // console.log(typeof onTextInputChange);
    onTextInputChange(name, event.target.value);
  });

  const handleBlur = useCallback(() => {
    if (inputValue.trim() === '') {
      setInputError('Input cannot be blank');
    } else {
      setInputError('');
    }
  }, [inputValue]);

  //   useEffect(() => {
  //     formHandler({ id, error: inputError });
  //   }, [inputError]);

  return (
      <label htmlFor={id} className={labelStyle}>
          <div>
              {label} {required && <span className={constantStyle}>*</span>}
          </div>
          <input
        id={id}
        type={type}
        name={name}
        value={inputValue}
        className={boxInput}
        inputMode={type}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        required
      />
          {inputError && <p className={`${constantStyle}`}>{inputError}</p>}
      </label>
  );
}

NewFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  formHandler: PropTypes.func.isRequired,
  onTextInputChange: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export { NewFormInput };
