import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewFormInput } from 'commons/formInputs/TextInput/NewTextInput';
import { APP_ROUTES } from 'configs/AppRoutes';
import { CommonButton } from '../commonElement/commonButton';

const SignUpPage = () => {
  const routeNavigator = useNavigate();
  const [InputValue, setInputValue] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('values:', InputValue);
    setTimeout(() => routeNavigator(APP_ROUTES.APP.PROFILE_PATH), 5000);
  };

  const handleInputChange = (name, value) => {
    setInputValue((prevState) => ({ ...prevState, [name]: value }));
  };

  function handleKeyDown(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }
  return (
      <div className="mx-auto text-center">
          <h1 className="text-2xl font-semibold my-10 break-words"><div className="text-purple-400">Hello!</div> Register to get started</h1>
          <form onSubmit={handleSubmit} className="mx-auto my-10 w-11/12 text-center">
              <NewFormInput
          name="Username"
          type="text"
          placeholder="Enter your username"
          label="Username"
          onTextInputChange={(name, value) => handleInputChange(name, value)}
          required
        />
              <NewFormInput
          name="Email"
          type="email"
          placeholder="username@example.com"
          label="Email"
          onTextInputChange={(name, value) => handleInputChange(name, value)}
          required
        />
              <NewFormInput
          name="Password"
          type="password"
          placeholder="Enter your password"
          label="Password"
          onTextInputChange={(name, value) => handleInputChange(name, value)}
          required
        />
              <NewFormInput
          name="Confirm Password"
          type="password"
          placeholder="Re-Enter Password"
          label="Confirm Password"
          onTextInputChange={(name, value) => handleInputChange(name, value)}
          required
        />
              <CommonButton
          ButtonName="Sign Up"
          btnStyle="Login"
          handleClick={(e) => handleSubmit(e)}
        />
          </form>

          <span>
              Already have an account?{' '}
              <span
          className="text-purple-300"
          role="button"
          onKeyDown={(e) => handleKeyDown(e)}
          tabIndex={0}
          onClick={() => routeNavigator(APP_ROUTES.APP.CREDENTIALS.LOGIN_PAGE)}
        >
                  Login
              </span>
          </span>
      </div>
  );
};

export { SignUpPage };