import React from "react";
import { connect } from "react-redux";
import { IconFactory, IconFactoryTypes } from "commons/Icons/IconFactory";
import { APP_ROUTES } from "configs/AppRoutes";
import { useNavigate } from "react-router-dom";
import ButtonElement from "./elements/buttonElement";
import { logoutAction } from "./profile.actions";
import { GoogleSSOButton } from "./elements/loginComponent";
import { googleLogout } from '@react-oauth/google';
import { refreshJwt, fetchProtectedData } from "commons/Google-OAuth/google_OAuth";

function UserProfile({ isUserSignedIn, userName, email, picture }) {    
    return (
        <div className="my-4 flex items-center justify-between gap-6 ">
            <img
                className="h-24 w-24 max-w-none rounded-full border-4 border-solid border-blue-500"
                src={picture}
                alt="User profile img"
            />
            
            {isUserSignedIn 
            ?<>
                <div className="flex flex-1 flex-col gap-2">
                    <h1 className="text-lg font-bold">{userName}</h1>
                    <p className="text-sm font-semibold">{email}</p>
                </div>
            </>
            :<>
                <div className="flex flex-1 flex-col gap-2">
                    <h1 className="text-lg">Sign-In required</h1>
                    <GoogleSSOButton />
                </div>
            </>
            }
        </div>
    );
}

function ProfileComponent(props) {
    const routeNavigator = useNavigate();
    const { isUserSignedIn, userName, logout, email, picture } = props;

    return (
        <>
            <UserProfile isUserSignedIn={isUserSignedIn} userName={userName} email={email} picture={picture} />
            <div className="flex flex-wrap justify-evenly">
                <div className="h-24 w-28 rounded-3xl bg-violet-300 p-4 " onClick={() => refreshJwt()}>
                    <h2 className="text-base font-semibold">Refresh JST</h2>
                    {/* <div className="pt-3 text-2xl font-bold">100</div> */}
                </div>
                <div className="h-24 w-28 rounded-3xl bg-purple-300  p-4 " onClick={() => fetchProtectedData()}>
                    <h2 className="text-base font-semibold">Access protected</h2>
                    {/* <div className="pt-3 text-2xl font-bold">100</div> */}
                </div>
                <div
                    className="h-24 w-28 rounded-3xl bg-rose-400 p-4 "
                    onClick={() => routeNavigator(APP_ROUTES.APP.CREATORS.NEW_STORY)}
                >
                    <h2 className="text-base font-semibold">Create Story</h2>
                    <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
                </div>
            </div>

            <div className="my-6 rounded-t-3xl">
                <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                    <IconFactory iconName={IconFactoryTypes.FILE} />
                    <p className="text-md flex-1 font-semibold">
                        Activities and Transactions
                    </p>
                    <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
                </div>
                <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                    <IconFactory iconName={IconFactoryTypes.PROFILE_EXCLAIMATION} />
                    <p className="text-md flex-1 font-semibold">Permissions</p>
                    <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
                </div>
                <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                    <IconFactory iconName={IconFactoryTypes.SETTINGS} />
                    <p className="text-md flex-1 font-semibold">Settings</p>
                    <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
                </div>
                <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                    <IconFactory iconName={IconFactoryTypes.MESSAGE} />
                    <p className="text-md flex-1 font-semibold">Contact Us</p>
                    <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
                </div>
                <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                    <IconFactory iconName={IconFactoryTypes.EXCLAIMATION} />
                    <p className="text-md flex-1 font-semibold">About Us</p>
                    <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
                </div>
            </div>

            <ButtonElement btnStyle="signOut" onClick={() => logout()} type="button">
                Sign Out
            </ButtonElement>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        userName: state.profile?.userName || null,
        email: state.profile?.email,
        picture: state.profile?.picture,
        isUserSignedIn: state.profile?.isUserSignedIn || false,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(logoutAction())
            googleLogout();
        },
    };
};

// Connect the component and export it
const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
export { Profile };
